import { ApiStatuses, Nullable } from "../../app/types";
import { IOrder } from "../orders";

export enum EPalletType {
  // Nespresso
  Hive = "hive",
  Bagger = "bagger",

  // Additions to IStore
  Boxer = "boxer",
  Stacker = "stacker",
  SingleItemHive = "sih",
  BufferHive = "buffer",
  Holder = "holder",

  //Z-Delivery
  Packer = "packer"
}

export interface IStation {
  location:	string;
  palletId: Nullable<string>;
  quantity: Nullable<number>;
  ordersIds: Nullable<string[]>;
  type: Nullable<EPalletType>;
}

export interface ISystemSliceState {
  stations: IStation[];
  products: ISystemProduct[];
  searchFilter: string;
  status: ApiStatuses;
  productsStatus: ApiStatuses;
  stationProductsStatus: ApiStatuses;
  stationOrdersStatus: ApiStatuses;
  selectedStation: Nullable<IStation>;
  stationProducts: IStationProduct[];
  stationOrders: IOrder[];
}

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type ProductMetadata = PartialRecord<string, string>;

export interface IVariant {
  quantity: number;
  metadata: ProductMetadata;
}

export interface ISystemProduct {
  availableQuantity: number;
  name: string;
  pendingQuantity: number;
  quantity: number;
  productId: number;
  reservedQuantity: number;
  sku: string;
  tenantId: number;
}

export interface IStationProductDetails {
  productId: number;
  sku: string;
  name: string;
}

export interface IStationProduct extends ISystemProduct {
  product: IStationProductDetails;
  variants?: IVariant[];
  key: number;
}

export type IStationProductWithMetadata = IStationProduct & ProductMetadata;