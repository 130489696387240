import { FC, SyntheticEvent, useRef, useState } from "react";
import classNames from "classnames/bind";
import { IconEdit, IconEditConfirm } from "../../assets";
import styles from "./EditableInput.module.css";
import { LMIconButton } from "../LMIconButton";

const cx = classNames.bind(styles);

interface Props {
  value: string;
  onConfirm: (value: string) => void;
  className?: string;
  disableEditing?: boolean;
}

export const EditableInput: FC<Props> = ({
  value: _value,
  onConfirm,
  className = "",
  disableEditing = false,
}) => {
  const [value, setValue] = useState(_value);
  const [editable, setEditable] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const handleClickToEdit = () => {
    if (editable || disableEditing) return;
    setEditable(true);
    setTimeout(() => {
      if (!input.current) return;
      input.current.focus();
    });
  };

  const handleChangeConfirm = () => {
    if (!editable) return;
    onConfirm(value);
    setEditable(false);
  };

  const handleChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    setValue(target.value);
  };

  return (
    <div
      className={`${cx({
        container: true,
        editable,
        "editing-disabled": disableEditing,
      })} ${className}`}
      onClick={handleClickToEdit}
    >
      <input
        ref={input}
        onChange={handleChange}
        className={styles.input}
        value={value}
        disabled={!editable}
      />
      {!disableEditing && (
        <LMIconButton
          className={styles["icon-edit"]}
          onClick={handleChangeConfirm}
        >
          {editable ? <IconEditConfirm /> : <IconEdit />}
        </LMIconButton>
      )}
    </div>
  );
};
