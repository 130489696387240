export const all_clients = ["clients:all"];
export const clients = ["clients:nespresso", "clients:istore"];

export const scopes = [
  "orders:get", "orders:update", "orders:create",
  "shipping:get", "shipping:update",
  "maintenance:get", "maintenance:create", "maintenance:auth",
  "systems:get", "systems:update",
  "keys:get", "keys:create", "keys:create:admin", "keys:update",
  "monitor:get",
  "stock:get",
  "products:get", "products:create", "products:delete", "products:update", "products:admin",
  "media:get", "media:create",
  "control:get", "control:create",
  "products:metadata:get", "products:metadata:create", "products:metadata:update", "products:metadata:delete",
  "estop:update"].concat(clients).concat(all_clients)