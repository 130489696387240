import { FC, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ColumnType } from "antd/lib/table";
import { format } from "date-fns";
import { EKeyPrivilegeOperations, IKey } from "../types";
import { actions } from "../slice";
import { StatusCell } from "../StatusCell";
import { QrDownloadCell } from "../QrDownloadCell";
import { AbilityContext, Can } from "../../casl";
import { ApiStatuses } from "../../../app/types";
import { DATE_FORMAT } from "../../../app/constants";
import { LMTable } from "../../../components/LMTable";
import { Tooltip } from "antd";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconPlus } from "../../../assets";
import styles from "./KeysList.module.css";

interface Props {
  keys: IKey[];
  page: number;
  pageSize: number;
  total: number;
  systemId: number;
  status: ApiStatuses;
}

export const KeysList: FC<Props> = ({
  keys,
  page,
  pageSize,
  total,
  systemId,
  status,
}) => {
  const { t } = useTranslation("keys");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const ability = useContext(AbilityContext);

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handlePageSizeChange = (updatedSize: string) => {
    dispatch(actions.setPageSize(Number(updatedSize)));
  };

  const columns = useMemo(
    () => [
      {
        title: t("key"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("createdBy"),
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: t("phone"),
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: t("created"),
        dataIndex: "createdOn",
        key: "createdOn",
        render: (time: string) => format(new Date(time), DATE_FORMAT),
      },
      {
        title: t("privileges"),
        dataIndex: "privileges",
        key: "privileges",
        render: (privileges: EKeyPrivilegeOperations[]) => (
          <Tooltip title={privileges.map((p) => t(p)).join(", ")}>
            <div className={styles.privileges}>
              {privileges.map((p) => t(p)).join(", ")}
            </div>
          </Tooltip>
        ),
      },
      {
        title: ability.can("update", "keys") ? t("active") : t("status"),
        dataIndex: "active",
        key: "active",
        render: (active: boolean, key: IKey) => (
          <StatusCell keyId={key.id} maintenanceKey={key} systemId={systemId} />
        ),
      },
      {
        title: t("code"),
        dataIndex: "code",
        key: "code",
        render: (active: boolean, key: IKey) => (
          <QrDownloadCell maintenanceKey={key} />
        ),
      },
    ],
    [t, ability, systemId]
  ) as ColumnType<IKey>[];

  const handleClickCreateNewKey = () => {
    searchParams.set("create", "true");
    setSearchParams(searchParams);
  };

  const handleSelectKey = (key: IKey) => {
    searchParams.set("keyId", key.id.toString());
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <LMTable
        total={{ title: t("keys"), amount: total }}
        totalSuffix={t("active")}
        dataSource={keys}
        rowKey="id"
        columns={columns}
        onRow={(row) => ({
          onClick: () => handleSelectKey(row),
          style: { cursor: "pointer" },
        })}
        action={
          <Can I="create" a="keys">
            <LMNewButton
              onClick={handleClickCreateNewKey}
              iconRight={<IconPlus />}
              type="primary"
              small
            >
              {t("create")}
            </LMNewButton>
          </Can>
        }
        loading={status === ApiStatuses.loading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        pageSize={pageSize}
        page={page}
      />
    </div>
  );
};
