import { FC } from "react";
import { EOrderStatus, EOrderGroups, IOrder } from "../types";
import { OrderBoardHeader } from "./OrderBoardHeader";
import { OrderBoardItem } from "../OrderBoardItem";
import styles from "./OrdersBoard.module.css";
import { H3 } from "../../../components/Typography";
import { useTranslation } from "react-i18next";

interface Props {
  orders: IOrder[];
}

type OrdersGrouped = Record<EOrderGroups, IOrder[]>;

const groupOrders = (orders: IOrder[]) => {
  const grouped: OrdersGrouped = {
    Pending: [],
    Queued: [],
    InProgress: [],
    Packed: [],
    Failed: [],
    Collected: [],
    Canceled: [],
  };

  orders.forEach((order) => {
    if (
      order.status === EOrderStatus.Pending ||
      order.status === EOrderStatus.Approved
    ) {
      grouped.Pending.push(order);
    }
    if (
      order.status === EOrderStatus.Delivering ||
      order.status === EOrderStatus.InProgress
    ) {
      grouped.InProgress.push(order);
    }
    if (
      order.status === EOrderStatus.Packed ||
      order.status === EOrderStatus.PackedManually ||
      order.status === EOrderStatus.DeliveryRequested
    ) {
      grouped.Packed.push(order);
    }
    if (
      order.status === EOrderStatus.Collected ||
      order.status === EOrderStatus.CollectedManually
    ) {
      grouped.Collected.push(order);
    }
    if (
      order.status === EOrderStatus.Failed ||
      order.status === EOrderStatus.DeliveryFailed ||
      order.status === EOrderStatus.PackingFailed ||
      order.status === EOrderStatus.ProcessingFailed
    ) {
      grouped.Failed.push(order);
    }
    if (order.status === EOrderStatus.Canceled) {
      grouped.Canceled.push(order);
    }
    if (order.status === EOrderStatus.Queued) {
      grouped.Queued.push(order);
    }
  });

  return grouped;
};

export const OrdersBoard: FC<Props> = ({ orders }) => {
  const { t } = useTranslation("orders");
  const groupedOrders = groupOrders(orders);
  return (
    <div className={styles.container}>
      {Object.keys(groupedOrders).map((groupName) => {
        const group = groupName as EOrderGroups;
        return (
          <div className={styles.column} key={group}>
            <OrderBoardHeader
              status={group}
              total={groupedOrders[group].length}
            />
            <div className={styles["items-container"]}>
              {groupedOrders[group].map((order) => (
                <OrderBoardItem order={order} key={order.id} />
              ))}
              {groupedOrders[group].length === 0 && (
                <H3 className={styles["no-orders"]}>{t("noOrders")}</H3>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
