import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ISystemDto, ISystemsSliceState, IUpdateSystemDto} from './types';
import { ApiStatuses } from '../../app/types';
import {
  getSystem,
  getSystems,
  updateSystem as _updateSystem
} from './api';
import { MessageOperation } from '../signalR';

export const initialState: ISystemsSliceState = {
  status: ApiStatuses.initial,
  systemStatus: ApiStatuses.initial,
  systems: []
};

export const fetchList = createAsyncThunk(
  'systems/fetchList',
  async () => {
    const response = await getSystems();
    return response.data;
  }
);

export const fetchSystem = createAsyncThunk(
  'systems/fetchSystem',
  async ({ systemId }: { systemId: number }) => {
    const response = await getSystem(systemId);
    return response.data;
  }
);

export const updateSystem = createAsyncThunk(
  'systems/updateSystem',
  async ({ systemId, system }: { systemId: number, system: IUpdateSystemDto }) => {
    const response = await _updateSystem(systemId, system);
    return response.data;
  }
);

const slice = createSlice({
  name: "systems",
  initialState,
  reducers: {
    setSelectedSystem(state: ISystemsSliceState, action: PayloadAction<number>) {
      const selectedSystem = state.systems.find(s => s.id === action.payload);
      if (!selectedSystem) return;
      state.selectedSystem = { ...selectedSystem };
    },
    updateSystemFromSignalR(state: ISystemsSliceState, action: PayloadAction<{ operation: MessageOperation, systemString: string }>) {
      if (action.payload.operation === MessageOperation.Updates) {
        const system = JSON.parse(action.payload.systemString) as ISystemDto;
        state.selectedSystem = system;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.systems = action.payload;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchSystem
      .addCase(fetchSystem.pending, (state) => {
        state.systemStatus = ApiStatuses.loading;
      })
      .addCase(fetchSystem.fulfilled, (state, action) => {
        state.systemStatus = ApiStatuses.success;
        state.selectedSystem = action.payload;
      })
      .addCase(fetchSystem.rejected, (state) => {
        state.systemStatus = ApiStatuses.fail;
      })
      // updateSystem
      .addCase(updateSystem.fulfilled, (state, action) => {
        state.selectedSystem = action.payload;
      })
  },
});

export const systems = slice.reducer;
export const actions = slice.actions;