import { FC, SyntheticEvent, useCallback, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  EProductClimax,
  EProductStatus,
  IProduct,
  IProductSku,
  IProductsListFilters,
} from "../types";
import { ColumnType } from "antd/lib/table";
import { actions } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses, SortTypes } from "../../../app/types";
import { LMTable } from "../../../components/LMTable";
import { ProductSkusList } from "../ProductSkusList";
import { extendByMetadata, sortSkus } from "../utils";
import { IconCheck } from "../../../assets";
import { ProductStatus } from "../ProductStatus";
import { LMIconButton } from "../../../components/LMIconButton";
import { approveProducts } from "../api";
import { useAppNotifications } from "../../../components/LMNotifications";
import { LMNewButton } from "../../../components/LMNewButton";
import { Button1 } from "../../../components/Typography";
import { AbilityContext, Can } from "../../casl";
import styles from "./ProductsPortfolioList.module.css";

interface Props {
  list: IProduct[];
  status: ApiStatuses;
  onSelectProduct: (productId: number) => void;
  filters: IProductsListFilters;
}

export const ProductsPortfolioList: FC<Props> = ({
  list,
  status,
  onSelectProduct,
  filters,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("productsPortfolio");
  const { contextHolder, success, error } = useAppNotifications();
  const ability = useContext(AbilityContext);

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handlePageSizeChange = (updatedSize: string) => {
    dispatch(actions.setPageSize(Number(updatedSize)));
  };

  const handleSortNameChange = (column: string, order: SortTypes) => {
    if (column === "name") {
      dispatch(actions.setNameSort(order));
    }
  };

  const handleApproveProduct = useCallback(
    async (evt: SyntheticEvent, id: number) => {
      evt.preventDefault();
      evt.stopPropagation();
      try {
        await approveProducts([id]);
        success(t("approveProductSuccess"));
      } catch {
        error(t("approveProductFail"));
      }
    },
    [error, success, t]
  );

  const handleApprovePendingProducts = useCallback(async () => {
    const pendingProductsIds = list
      .filter((p) => p.status === EProductStatus.Pending)
      .map((p) => p.id);
    try {
      await approveProducts(pendingProductsIds);
      success(t("approveProductsSuccess"));
    } catch {
      error(t("approveProductsFail"));
    }
  }, [error, success, t, list]);

  const getPendingProductsCount = useCallback(() => {
    return list.filter((p) => p.status === EProductStatus.Pending).length;
  }, [list]);

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        key: "name",
        render: (product: IProduct) => {
          return product.name;
        },
        fixed: "left",
        width: 170,
        sorter: true,
      },
      {
        title: t("status"),
        key: "status",
        fixed: "left",
        width: 120,
        render: (product: IProduct) => {
          return <ProductStatus status={product.status} />;
        },
      },
      {
        title: t("category"),
        key: "category",
        width: 110,
        render: (product: IProduct) => {
          return product.category?.name || "—";
        },
      },
      ...extendByMetadata(list).metadataList.map((metadataName) => ({
        title: metadataName.title,
        key: metadataName.value,
        dataIndex: metadataName.value,
        width: 100,
      })),
      {
        title: t("external"),
        key: "external",
        width: 120,
        dataIndex: "external",
        render: (external: boolean) => {
          return external ? (
            <div className={styles.external}>
              <IconCheck stroke="var(--color-primary-80)" />
            </div>
          ) : (
            <></>
          );
        },
      },
      {
        title: t("climax"),
        key: "climax",
        dataIndex: "climax",
        width: 100,
        render: (climax: EProductClimax) => {
          return t(climax.toLowerCase());
        },
      },
      {
        title: t("sku"),
        key: "skus",
        width: 150,
        dataIndex: "skus",
        render: (skus: IProductSku[]) => {
          return <ProductSkusList skus={skus.slice().sort(sortSkus)} />;
        },
      },
      {
        title: "",
        width: 78,
        key: "action",
        fixed: "right",
        render: (product: IProduct) => {
          if (product.status === EProductStatus.Pending) {
            return (
              <Can I="admin" a="products">
                <LMIconButton
                  color="white"
                  onClick={(evt: SyntheticEvent) =>
                    handleApproveProduct(evt, product.id)
                  }
                >
                  <IconCheck />
                </LMIconButton>
              </Can>
            );
          }
          return null;
        },
      },
    ],
    [t, handleApproveProduct, list]
  ) as ColumnType<IProduct>[];

  const onRow = (order: IProduct) => ({
    onClick: () =>
      ability.can("update", "products") ? onSelectProduct(order.id) : null,
    style: {
      cursor: ability.can("update", "products") ? "pointer" : "initial",
    },
  });

  return (
    <>
      {contextHolder}
      <LMTable
        action={
          getPendingProductsCount() === 0 ? undefined : (
            <Can I="admin" a="products">
              <LMNewButton
                color="transparent"
                className={styles.button}
                onClick={handleApprovePendingProducts}
              >
                {t("approvePendingProducts")}{" "}
                <Button1 className={styles.label}>
                  {getPendingProductsCount()}
                </Button1>
              </LMNewButton>
            </Can>
          )
        }
        loading={status === ApiStatuses.loading}
        columns={columns}
        dataSource={extendByMetadata(list).productsWithMetadata}
        total={{ title: t("products"), amount: filters.total }}
        onChangePageSize={handlePageSizeChange}
        onChangePage={handlePageChange}
        onSortChange={handleSortNameChange}
        pageSize={filters.pageSize}
        page={filters.page}
        onRow={onRow}
        scroll={{ x: 1300 }}
      />
    </>
  );
};
