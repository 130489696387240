import { FC, KeyboardEvent } from "react";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import { IProductSku } from "../types";
import { useTranslation } from "react-i18next";
import { LMNewInput } from "../../../components";
import { addProductSkuBarcode } from "../api";
import { refreshSelectedProduct } from "../slice";
import { AppDispatch } from "../../../app/store";
import { useAppNotifications } from "../../../components/LMNotifications";
import { Can } from "../../casl";
import styles from "./ProductSkuItem.module.css";

interface Props {
  sku: IProductSku;
}

export const ProductSkuItem: FC<Props> = ({ sku }) => {
  const { t } = useTranslation("productsPortfolio");
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm<{ barcode: string }>();
  const { contextHolder, success, error } = useAppNotifications();

  const handleAddBarcode = async () => {
    const barcode = form.getFieldValue("barcode");
    if (!barcode) {
      error(t("emptyBarcodeError"));
      return;
    }
    try {
      await addProductSkuBarcode(sku.sku, barcode);
      await dispatch(refreshSelectedProduct());
      form.resetFields();
      success(t("addBarcodeSuccess"));
    } catch {
      error(t("addBarcodeError"));
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleAddBarcode();
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        layout={"vertical"}
        form={form}
        className={styles.form}
        autoComplete="off"
      >
        <Can I="update" a="products">
          <Form.Item name="barcode" label={t("barCodes")}>
            <LMNewInput
              onKeyDown={handleKeyDown}
              placeholder={t("barCodesAddPlaceholder")}
            />
          </Form.Item>
        </Can>
      </Form>
      {sku.barcodes && (
        <div className={styles.barcodes}>
          {sku.barcodes.map((barcode) => (
            <div key={barcode} className={styles.barcode}>
              {barcode}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
