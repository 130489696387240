import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconPlus } from "../../../assets";
import { ProductSkuForm } from "../ProductSkuForm";
import { IProductSku } from "../types";
import { ProductSkuItem, ProductSkuItemHeader } from "../ProductSkuItem";
import styles from "./ProductSkus.module.css";
import { useAppNotifications } from "../../../components/LMNotifications";
import { Can } from "../../casl";

const { Panel } = Collapse;

interface Props {
  productId: number;
  skus: IProductSku[];
}

export const ProductSkus: FC<Props> = ({ productId, skus }) => {
  const { t } = useTranslation("productsPortfolio");
  const { contextHolder, success, error } = useAppNotifications();
  const [isCreateFormShown, setIsCreateFormShown] = useState(false);

  const showCreateForm = () => setIsCreateFormShown(true);
  const hideCreateForm = () => setIsCreateFormShown(false);

  const handleAddSkuSuccess = () => success(t("addSkuSuccess"));
  const handleAddSkuError = () => error(t("addSkuError"));

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.head}>
        <Can I="update" a="products">
          <LMNewButton
            color="default"
            iconRight={<IconPlus stroke="var(--color-gray-80)" />}
            onClick={showCreateForm}
          >
            {t("addSku")}
          </LMNewButton>
        </Can>
      </div>
      {isCreateFormShown && (
        <ProductSkuForm
          onClose={hideCreateForm}
          productId={productId}
          onSuccess={handleAddSkuSuccess}
          onError={handleAddSkuError}
        />
      )}
      <Collapse className={styles["skus-list"]} expandIconPosition="end">
        {skus.map((sku) => (
          <Panel
            className={styles["sku-item"]}
            header={<ProductSkuItemHeader sku={sku} />}
            key={sku.id}
          >
            <ProductSkuItem sku={sku} />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
