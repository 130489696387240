import { Modal } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LMNewButton } from "../LMNewButton";
import styles from "./FormModal.module.css";

interface Props {
  open: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  content: JSX.Element;
  cancelText?: string;
  submitText?: string;
  width?: number;
}

export const FormModal: FC<Props> = ({
  open,
  onConfirm,
  onCancel,
  content,
  cancelText,
  submitText,
  width = 400,
}) => {
  const { t } = useTranslation("layout");
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={width}
      className={styles.container}
    >
      <div className={styles.content}>{content}</div>

      <div className={styles.buttons}>
        <LMNewButton
          color="default"
          onClick={onCancel}
          className={styles.button}
        >
          {cancelText || t("cancel")}
        </LMNewButton>
        <LMNewButton onClick={onConfirm} className={styles.button}>
          {submitText || t("submit")}
        </LMNewButton>
      </div>
    </Modal>
  );
};
