import { FC, useCallback, useContext } from "react";
import { Drawer, Tabs } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IProduct, IProductCategory } from "../types";
import { EditableInput } from "../../../components/EditableInput";
import { Nullable } from "../../../app/types";
import { updateProduct } from "../api";
import { useAppNotifications } from "../../../components/LMNotifications";
import { fetchProductDetails, refreshList } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ProductSkus } from "../ProductSkus";
import { AbilityContext } from "../../casl";
import { sortSkus } from "../utils";
import styles from "./ProductPortfolioDetails.module.css";
import { UpdateProductForm } from "../UpdateProductForm";
import { IMetadataEntry } from "../../metadata/types";

interface Props {
  product: Nullable<IProduct>;
  categories: IProductCategory[];
  metadataEntries: IMetadataEntry[];
  onHide: () => void;
}

export const ProductPortfolioDetails: FC<Props> = ({
  product,
  onHide,
  categories,
  metadataEntries,
}) => {
  const { t } = useTranslation("productsPortfolio");
  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();
  const ability = useContext(AbilityContext);

  const refresh = useCallback(() => {
    if (!product) return;
    dispatch(refreshList());
    dispatch(fetchProductDetails(product.id));
  }, [dispatch, product]);

  const handleNameChangeConfirm = async (name: string) => {
    if (!product) return;
    try {
      await updateProduct(product.id, { name });
      success(t("nameSuccess"));
      refresh();
    } catch {
      error(t("nameError"));
    }
  };

  const handleUpdateSuccess = () => {
    success(t("updateProductSuccess"));
  };

  const handleUpdateError = () => {
    error(t("updateProductError"));
  };

  const handleApproveSuccess = () => {
    success(t("approveProductSuccess"));
  };

  const handleApproveError = () => {
    error(t("approveProductFail"));
  };

  const handleDeclineSuccess = () => {
    success(t("declineProductSuccess"));
  };

  const handleDeclineError = () => {
    error(t("declineProductFail"));
  };

  return (
    <>
      <Drawer
        title={
          product ? (
            <EditableInput
              className={styles.editable}
              value={product.name}
              onConfirm={handleNameChangeConfirm}
              disableEditing={!ability.can("update", "products")}
            />
          ) : (
            <></>
          )
        }
        placement="right"
        onClose={onHide}
        className={styles.drawer}
        open={!!product}
      >
        {product && (
          <Tabs
            centered
            className={styles.tabs}
            items={[
              {
                label: t("details"),
                key: "details",
                children: (
                  <UpdateProductForm
                    product={product}
                    categories={categories}
                    metadataEntries={metadataEntries}
                    onClose={onHide}
                    onSuccess={handleUpdateSuccess}
                    onError={handleUpdateError}
                    onApproveSuccess={handleApproveSuccess}
                    onApproveError={handleApproveError}
                    onDeclineError={handleDeclineError}
                    onDeclineSuccess={handleDeclineSuccess}
                  />
                ),
              },
              {
                label: t("productSkus"),
                key: "productSkus",
                children: (
                  <ProductSkus
                    productId={product.id}
                    skus={product.skus.slice().sort(sortSkus)}
                  />
                ),
              },
            ]}
          ></Tabs>
        )}
      </Drawer>
      {contextHolder}
    </>
  );
};
