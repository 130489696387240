import { useTranslation } from "react-i18next";
import { Form, Tooltip } from "antd";
import { LMDropdown, LMNewInput } from "../../../components";
import {
  IProductFormValues,
  IProductCategory,
  IProduct,
  EProductStatus,
} from "../types";
import { FC, useCallback } from "react";
import {
  approveProducts,
  createCategory,
  declineProducts,
  updateProduct,
} from "../api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { fetchCategories, refreshList } from "../slice";
import { IconCheck, IconPlus, IconTimes } from "../../../assets";
import { IMetadataEntry } from "../../metadata/types";
import { LMNewButton } from "../../../components/LMNewButton";
import { ProductStatus } from "../ProductStatus";
import { LMIconButton } from "../../../components/LMIconButton";
import { Can } from "../../casl";
import { mapProductToUpdate } from "./utils";
import styles from "./UpdateProductForm.module.css";

const NEW_CATEGORY_VALUE = "create-new-category";

interface Props {
  onClose: () => void;
  categories: IProductCategory[];
  metadataEntries: IMetadataEntry[];
  product: IProduct;
  onSuccess: () => void;
  onError: () => void;
  onApproveSuccess: () => void;
  onApproveError: () => void;
  onDeclineSuccess: () => void;
  onDeclineError: () => void;
}

export const UpdateProductForm: FC<Props> = ({
  onClose,
  categories,
  metadataEntries,
  product,
  onSuccess,
  onError,
  onApproveError,
  onApproveSuccess,
  onDeclineError,
  onDeclineSuccess,
}) => {
  const { t } = useTranslation("productsPortfolio");

  const [form] = Form.useForm<IProductFormValues>();
  const dispatch = useDispatch<AppDispatch>();

  const getInitialValues = useCallback(() => {
    const values = {
      categoryId: product.categoryId?.toString(),
      // climax: product.climax.toLowerCase(),
      // metadata: product.metadata,
      // external: product.external,
    };
    return values;
  }, [product]);

  const onFinish = async (values: IProductFormValues) => {
    try {
      if (values.categoryName) {
        const category = await createCategory({ name: values.categoryName });
        await updateProduct(
          product.id,
          mapProductToUpdate({
            ...values,
            categoryId: category.data.id.toString(),
          })
        );
        await dispatch(fetchCategories());
      } else {
        await updateProduct(product.id, mapProductToUpdate(values));
      }
      await dispatch(refreshList());
      form.resetFields();
      onSuccess();
      onClose();
    } catch (e: any) {
      onError();
    }
  };

  const categoriesItems = categories.map((category) => ({
    name: category.name,
    value: category.id.toString(),
  }));

  const addCategoryItem = {
    name: t("createCategory"),
    value: NEW_CATEGORY_VALUE,
    icon: (
      <div className={styles["create-category-icon"]}>
        <IconPlus stroke="var(--color-primary-80)" />
      </div>
    ),
  };

  const category = Form.useWatch("categoryId", form);

  const handleSubmit = () => form.submit();

  const handleApprove = async () => {
    try {
      await approveProducts([product.id]);
      await dispatch(refreshList());
      onApproveSuccess();
      onClose();
    } catch {
      onApproveError();
    }
  };

  const handleDecline = async () => {
    try {
      await declineProducts([product.id]);
      await dispatch(refreshList());
      onDeclineSuccess();
      onClose();
    } catch {
      onDeclineError();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <ProductStatus status={product.status} />
        {product.status === EProductStatus.Pending && (
          <Can I="admin" a="products">
            <div className={styles["statuses-buttons"]}>
              <Tooltip placement="top" title={t("declineProduct")}>
                <LMIconButton color="white" onClick={handleDecline}>
                  <IconTimes />
                </LMIconButton>
              </Tooltip>
              <Tooltip placement="top" title={t("approveProduct")}>
                <LMIconButton color="white" onClick={handleApprove}>
                  <IconCheck stroke="var(--color-green-70)" />
                </LMIconButton>
              </Tooltip>
            </div>
          </Can>
        )}
      </div>
      <Form
        layout={"vertical"}
        form={form}
        className={styles.form}
        initialValues={getInitialValues()}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className={styles.fields}>
          <Form.Item name="categoryId" label={t("category")}>
            <LMDropdown
              placeholder={t("dropdownPlaceholder")}
              items={[addCategoryItem, ...categoriesItems]}
            />
          </Form.Item>
          {category === NEW_CATEGORY_VALUE && (
            <Form.Item name="categoryName" label={t("categoryName")}>
              <LMNewInput placeholder={t("categoryNamePlaceholder")} />
            </Form.Item>
          )}
          {/* <Form.Item
            name="climax"
            label={t("climax")}
            rules={[{ required: true, message: t("requiredMessage") }]}
          >
            <LMDropdown
              placeholder={t("dropdownPlaceholder")}
              items={Object.values(EProductClimax).map((value) => ({
                name: value,
                value,
              }))}
            />
          </Form.Item>
          <div className={styles["check-container"]}>
            <Form.Item
              name="external"
              label={t("externalLabel")}
              valuePropName="checked"
            >
              <LMCheck />
            </Form.Item>
            <Button2 className={styles["check-label"]}>{t("enabled")}</Button2>
          </div> */}
        </div>
        {/* <MetadataFields withEdit entries={metadataEntries} /> */}
        <div className={styles.buttons}>
          <LMNewButton
            color="default"
            onClick={onClose}
            className={styles.button}
          >
            {t("cancel")}
          </LMNewButton>

          <LMNewButton onClick={handleSubmit}>{t("saveChanges")}</LMNewButton>
        </div>
      </Form>
    </div>
  );
};
