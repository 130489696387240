import { useDispatch, useSelector } from "react-redux";
import { SystemProductsFilters } from "../SystemProductsFilters";
import { AppDispatch, RootState } from "../../../app/store";
import { FC, useCallback, useEffect, useState } from "react";
import { actions, fetchSystemProducts } from "../slice";
import { SystemProductsList } from "../SystemProductsList";
import { useSearchParams } from "react-router-dom";
import { SystemStationDetails } from "../SystemStationDetails";
import styles from "./SystemProducts.module.css";
import { SystemStationOrders } from "../SystemStationOrders";

interface Props {
  tenantId: number;
  systemId: number;
}

export const SystemProducts: FC<Props> = ({ tenantId, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { searchFilter, products, productsStatus, selectedStation, stations } =
    useSelector((state: RootState) => {
      return state.system;
    });

  const filterProducts = useCallback(() => {
    let _products = products;
    if (searchFilter) {
      _products = _products.filter((p) => {
        if (p.name.toLowerCase().includes(searchFilter.toLowerCase())) {
          return true;
        }
        if (p.sku.toLowerCase().includes(searchFilter.toLowerCase())) {
          return true;
        }
        return false;
      });
    }
    return _products;
  }, [searchFilter, products]);

  const [filteredProducts, setFilteredProducts] = useState(filterProducts());

  const getProducts = useCallback(() => {
    dispatch(fetchSystemProducts(tenantId));
  }, [tenantId, dispatch]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    setFilteredProducts(filterProducts());
  }, [filterProducts]);

  useEffect(() => {
    const palletId = searchParams.get("palletId");
    const pallet = stations.find((s) => s.palletId === palletId);
    if (selectedStation?.palletId !== palletId) {
      dispatch(actions.setSelectedPallet(pallet || null));
    }
    dispatch(
      actions.setFilters({
        searchFilter: searchParams.get("searchString") || "",
      })
    );
  }, [searchParams, dispatch, selectedStation, stations]);

  const handleCloseDrawer = () => {
    searchParams.delete("palletId");
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <SystemProductsFilters searchString={searchFilter} />
      <SystemProductsList status={productsStatus} products={filteredProducts} />
      <SystemStationDetails systemId={systemId} onHide={handleCloseDrawer} />
      <SystemStationOrders
        systemId={systemId}
        onHide={handleCloseDrawer}
        tenantId={tenantId}
      />
    </div>
  );
};
