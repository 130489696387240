import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from './en';
import { he } from './he';

export const initTranslation = (lng = 'en') => {
  i18n
  .use(initReactI18next)
  .init({
    resources: { en, he },
    lng: lng,
    fallbackLng: lng,
    interpolation: {
      escapeValue: false
    },
    parseMissingKeyHandler: (key: string, test: any) => {
      throw new Error(`Translation is missing for the key ${key}`);
    }
  });
}

