import { format } from "date-fns";
import React, { FC } from "react";
import { DATE_TIME_NO_SECONDS_COMPACT_FORMAT } from "../../../app/constants";
import { IconTimelineArrow } from "../../../assets";
import { Button2 } from "../../../components/Typography";
import { IStatusModelExtended } from "./OrderTimeline";
import styles from "./OrderTimeline.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  item: IStatusModelExtended;
  isFirst: boolean;
}

const calculateHeight = (item: IStatusModelExtended, isFirst: boolean) => {
  if (item.span === 0) return "auto";
  if (isFirst) return item.span * 80;
  return item.span * 80 + 26;
};

export const OrderTimelineItem: FC<Props> = ({ item, isFirst }) => {
  const { t } = useTranslation("order");

  return (
    <React.Fragment key={item.id}>
      <div
        className={styles.gap}
        style={{
          minHeight: isFirst ? 0 : 28,
          height: calculateHeight(item, isFirst),
        }}
      >
        {item.difference && (
          <div className={styles["gap-content"]}>
            <IconTimelineArrow />
            {item.difference}
          </div>
        )}
      </div>
      <div className={`${styles.item} ${styles[item.side]}`}>
        <div className={styles.dot} />
        <Button2 className={styles.status}>{t(item.status)}</Button2>
        <div className={styles.date}>
          {format(
            new Date(item.eventTime),
            DATE_TIME_NO_SECONDS_COMPACT_FORMAT
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
