import { FC, useState } from "react";
import Table from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IIndexCellProduct, IndexingTypes } from "../types";
import {
  calculateTotalForType,
  getCompareTableColumns,
  mapProductIndexPairs,
} from "./utils";
import { RelevanceDot } from "../../../components";
import styles from "./IndexingDetails.module.css";

interface Props {
  cellProducts: IIndexCellProduct[];
}

export const IndexingCompareTable: FC<Props> = ({ cellProducts }) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation("indexing");

  const cellProductsMerged = mapProductIndexPairs(cellProducts);

  const columns = getCompareTableColumns(t);

  const handlePageChange = (updatedPage: number) => {
    setPage(updatedPage);
  };

  return (
    <Table
      dataSource={cellProductsMerged}
      rowKey="id"
      columns={columns}
      pagination={{
        showSizeChanger: false,
        size: "small",
        current: page,
        onChange: handlePageChange,
        pageSize: 10,
        total: cellProductsMerged.length,
      }}
      summary={(pageData) => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                <p className={styles["total-title"]}>
                  {t("totalQuantity")}
                  <RelevanceDot isRelevant />
                </p>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={3}>
                <div className={styles.totals}>
                  <div className={styles.total}>{t("unknown")} </div>
                  <div className={styles.total}>{t("unrecognized")} </div>
                  <div className={styles.total}>{t("indexed")} </div>
                  <div className={styles.total}>{t("missing")} </div>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <div className={styles.total}>
                  <span className={styles["total-value"]}>
                    {calculateTotalForType(
                      cellProductsMerged,
                      IndexingTypes.Unknown
                    )}
                  </span>
                </div>
                <div className={styles.total}>
                  <span className={styles["total-value"]}>
                    {calculateTotalForType(
                      cellProductsMerged,
                      IndexingTypes.UnRecognized
                    )}
                  </span>
                </div>
                <div className={styles.total}>
                  <span className={styles["total-value"]}>
                    {calculateTotalForType(
                      cellProductsMerged,
                      IndexingTypes.Indexed
                    )}
                  </span>
                </div>
                <div className={styles.total}>
                  <span className={styles["total-value"]}>
                    {calculateTotalForType(
                      cellProductsMerged,
                      IndexingTypes.Missing
                    )}
                  </span>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
