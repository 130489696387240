import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { AppDispatch, RootState } from "../../app/store";
import { useSearchParams } from "react-router-dom";
import {
  fetchCategories,
  fetchProductPortfolio,
  productsPortfolioActions,
  fetchProductDetails,
  ProductPortfolioDetails,
} from "../../modules/productsPortfolio";
import {
  ProductsPortfolioList,
  ProductsPortfolioFilters,
  IProductFormValues,
  CreateProductForm,
} from "../../modules/productsPortfolio";
import { FormModal } from "../../components/FormModal";
import { fetchMetadataList } from "../../modules/metadata";
import styles from "./ProductsPortfolio.module.css";

export const ProductsPortfolio = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [createProductModalOpen, setCreateProductModalOpen] = useState(false);
  const [form] = Form.useForm<IProductFormValues>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("productsPortfolio");

  const { status, list, categories, selectedProduct, filters } = useSelector(
    (state: RootState) => {
      return state.productsPortfolio;
    }
  );

  const metadataEntries = useSelector((state: RootState) => {
    return state.metadata.list;
  });

  const getProductsPortfolio = useCallback(() => {
    dispatch(fetchProductPortfolio(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    dispatch(
      productsPortfolioActions.setFilters({
        searchString: searchParams.get("searchString") || "",
        categories: searchParams.get("categories") || "",
        disabledSystems: searchParams.get("disabledSystems") === "true",
        status: searchParams.get("status") || "",
      })
    );
  }, [searchParams, dispatch]);

  const getProductsCategories = useCallback(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const getMetadata = useCallback(() => {
    dispatch(fetchMetadataList());
  }, [dispatch]);

  useEffect(() => {
    getProductsPortfolio();
    getProductsCategories();
    getMetadata();
  }, [getProductsPortfolio, getProductsCategories, getMetadata]);

  useEffect(() => {
    const productId = searchParams.get("productId");
    if (!productId) return;
    if (selectedProduct && selectedProduct.id === Number(productId)) return;
    dispatch(fetchProductDetails(Number(productId)));
  }, [searchParams, dispatch, selectedProduct]);

  const showCreateProductModal = () => setCreateProductModalOpen(true);
  const hideCreateProductModal = () => setCreateProductModalOpen(false);

  const hideDetailsDrawer = () => {
    searchParams.delete("productId");
    setSearchParams(searchParams);
    dispatch(productsPortfolioActions.clearSelectedProduct());
  };

  const getProductDetails = (productId: number) => {
    searchParams.set("productId", productId.toString());
    setSearchParams(searchParams);
    dispatch(fetchProductDetails(productId));
  };

  const handleConfirmCreateProduct = () => form.submit();

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <ProductsPortfolioFilters
          filters={filters}
          categories={categories}
          onShowCreateProductModal={showCreateProductModal}
        />
      </div>
      <div className={styles.list}>
        <ProductsPortfolioList
          list={list}
          status={status}
          onSelectProduct={getProductDetails}
          filters={filters}
        />
      </div>
      <FormModal
        onConfirm={handleConfirmCreateProduct}
        submitText={t("create")}
        content={
          <CreateProductForm
            form={form}
            onClose={hideCreateProductModal}
            categories={categories}
            metadataEntries={metadataEntries}
          />
        }
        open={createProductModalOpen}
        onCancel={hideCreateProductModal}
      />
      <ProductPortfolioDetails
        product={selectedProduct}
        categories={categories}
        onHide={hideDetailsDrawer}
        metadataEntries={metadataEntries}
      />
    </div>
  );
};
