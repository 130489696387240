import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStation, ISystemSliceState } from './types';
import { ApiStatuses, Nullable } from '../../app/types';
import {
  getStationOrders,
  getStationProducts,
  getStations,
  getSystemProducts
} from './api';

export const initialState: ISystemSliceState = {
  stations: [],
  products: [],
  searchFilter: "",
  status: ApiStatuses.initial,
  productsStatus: ApiStatuses.initial,
  stationProductsStatus: ApiStatuses.initial,
  stationOrdersStatus: ApiStatuses.initial,
  stationOrders: [],
  selectedStation: null,
  stationProducts: [],
};

export const fetchStations = createAsyncThunk(
  'system/fetchStations',
  async (systemId: number) => {
    const response = await getStations(systemId);
    return response.data;
  }
);

export const fetchSystemProducts = createAsyncThunk(
  'system/fetchSystemProducts',
  async (tenantId: number) => {
    const response = await getSystemProducts(tenantId);
    return response.data;
  }
);

export const fetchStationProducts = createAsyncThunk(
  'system/fetchStationProducts',
  async ({ systemId, palletId }: { systemId: number, palletId: string }) => {
    const response = await getStationProducts(systemId, palletId);
    return response.data;
  }
);

export const fetchStationOrders = createAsyncThunk(
  'system/fetchStationOrders',
  async ({ tenantId, orderIds }: { tenantId: number, orderIds: string[] }) => {
    const response = await getStationOrders(tenantId, orderIds);
    return response.data;
  }
);

const slice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setFilters(state: ISystemSliceState, action: PayloadAction<{ searchFilter: string }>) {
      state.searchFilter = action.payload.searchFilter;
    },
    setSelectedPallet(state: ISystemSliceState, action: PayloadAction<Nullable<IStation>>) {
      state.selectedStation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchStations
      .addCase(fetchStations.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchStations.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.stations = action.payload;
      })
      .addCase(fetchStations.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchSystemProducts
      .addCase(fetchSystemProducts.pending, (state) => {
        state.productsStatus = ApiStatuses.loading;
      })
      .addCase(fetchSystemProducts.fulfilled, (state, action) => {
        state.productsStatus = ApiStatuses.success;
        state.products = action.payload;
      })
      .addCase(fetchSystemProducts.rejected, (state) => {
        state.productsStatus = ApiStatuses.fail;
      })
      // fetchStationProducts
      .addCase(fetchStationProducts.pending, (state) => {
        state.stationProductsStatus = ApiStatuses.loading;
      })
      .addCase(fetchStationProducts.fulfilled, (state, action) => {
        state.stationProductsStatus = ApiStatuses.success;
        state.stationProducts = action.payload;
      })
      .addCase(fetchStationProducts.rejected, (state) => {
        state.stationProductsStatus = ApiStatuses.fail;
      })
      // fetchStationOrders
      .addCase(fetchStationOrders.pending, (state) => {
        state.stationOrdersStatus = ApiStatuses.loading;
      })
      .addCase(fetchStationOrders.fulfilled, (state, action) => {
        state.stationOrdersStatus = ApiStatuses.success;
        state.stationOrders = action.payload.values;
      })
      .addCase(fetchStationOrders.rejected, (state) => {
        state.stationOrdersStatus = ApiStatuses.fail;
      })
  },
});

export const system = slice.reducer;
export const actions = slice.actions;