import { ApiStatuses, IPagination, Nullable, SortTypes } from "../../app/types";

export interface IProductsListFilters extends IPagination {
  page: number;
  pageSize: number;
  total: number;
  searchString: string;
  categoriesFilter: string[];
  statusFilter: string;
  disabledSystemsFilter: boolean;
  nameFilterSort: SortTypes;
}

export interface IProductsPortfolioSliceState {
  list: IProduct[];
  filters: IProductsListFilters;
  status: ApiStatuses;
  selectedProduct: Nullable<IProduct>;
  categories: IProductCategory[];
}

export interface IProductSku {
  id: number;
  createdOn: string;
  productSkuGroupId: number;
  sku: string;
  updatedOn: string;
  barcodes?: string[];
}

export interface IProductCategory {
  id: number;
  name: string;
}

export interface IProduct {
  updatedOn: string;
  createdOn: string;
  category?: IProductCategory;
  categoryId?: number;
  status: EProductStatus;
  climax: EProductClimax;
  id: number;
  name: string;
  external: boolean;
  metadata: Record<string, string>;
  skus: IProductSku[];
}

export enum EProductClimax {
  Frozen = "frozen",
  Chilled = "chilled",
  Cold = "cold"
}

export enum EProductStatus {
  Pending = "Pending",
  Approved = "Approved"
}

export interface IProductFormValues {
  name: string;
  categoryId: Nullable<string>;
  categoryName: Nullable<string>;
  climax: EProductClimax;
  external: boolean;
  metadata: Record<string, string>;
}

export interface ICreateProduct {
  name: string;
  categoryName: Nullable<string>;
  climax: EProductClimax;
  external: boolean;
  metadata: Record<string, string>;
}

export interface IUpdateProduct {
  categoryId: Nullable<string>;
}

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type ProductMetadata = PartialRecord<string, string>;

export type IOrderWithMetadata = IProduct & ProductMetadata;