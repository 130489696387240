import { IIndexingRange, IIndexingRangeForm } from "./types";

export const generateId = () => Math.floor(Math.random() * 100);

export const mapRanges = (ranges: IIndexingRangeForm[]): IIndexingRange[] => (
  ranges.map(r => ({ palletId: r.palletId, side: r.side, start: r.start, end: r.end }))
);

export const mergeOverlappingRanges = (
  ranges: IIndexingRangeForm[]
): IIndexingRangeForm[] => {
  const sortedRanges = ranges.sort(
    // By start, ascending
    (a, b) => (a.start || 0) - (b.start || 0)
  );

  return sortedRanges.reduce((accumulated, range) => {
    // Skip the first range
    if (accumulated.length === 0) {
      return [range];
    }

    // Find current range
    const currentRange = accumulated.find(r => r.palletId === range.palletId && r.side === range.side);
    if (!currentRange) {
      return [...accumulated, range];
    }

    const prev = accumulated.pop() as IIndexingRangeForm;

    if ((range.end || 0) <= (prev.end || 0)) {
      // Current range is completely inside previous
      return [...accumulated, prev];
    }

    // Merges overlapping (<) and contiguous (==) ranges
    if ((range.start || 0) <= (prev.end || 0)) {
      // Current range overlaps previous
      return [...accumulated, { ...range, start: prev.start, end: range.end }];
    }

    // Ranges do not overlap
    return [...accumulated, prev, range];
  }, [] as IIndexingRangeForm[]);
};