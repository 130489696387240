import { Segmented, Tooltip } from "antd";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { LoaderWrapper } from "../../../components";
import { fetchState } from "..";
import { Can } from "../../casl";
import classNames from "classnames/bind";
import { PopoverConfirmation } from "../../../components/PopoverConfirmation";
import { updateEStop } from "../slice";
import { LMIconButton } from "../../../components/LMIconButton";
import { IconQuestionCircle } from "../../../assets";
import styles from "./EStopButton.module.css";

const cx = classNames.bind(styles);

export const EStopButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { state, status } = useSelector((state: RootState) => {
    return state.systemsAttributes;
  });

  const { selectedSystem } = useSelector((state: RootState) => {
    return state.systems;
  });

  const getState = useCallback(() => {
    if (!selectedSystem?.id) return;
    dispatch(fetchState(selectedSystem.id));
  }, [selectedSystem, dispatch]);

  useEffect(() => {
    getState();
  }, [getState]);

  const { t } = useTranslation("layout");

  const handleUpdateEStop = () => {
    if (!state || !selectedSystem) return;
    dispatch(
      updateEStop({ systemId: selectedSystem.id, status: !state.eStop })
    );
  };

  return (
    <Can I="update" a="estop">
      <LoaderWrapper status={status}>
        {state && selectedSystem ? (
          <div className={styles.container}>
            <h6 className={styles.title}>{t("rStop")}</h6>
            <Tooltip placement="bottom" title={t("rStopTip")}>
              <LMIconButton>
                <IconQuestionCircle />
              </LMIconButton>
            </Tooltip>
            <div className={styles.action}>
              <PopoverConfirmation
                onConfirm={handleUpdateEStop}
                confirmText={t("activate")}
                text={
                  state.eStop ? t("rStopDisableWarn") : t("rStopEnableWarn")
                }
              >
                <Segmented
                  className={cx({
                    segmented: true,
                    red: state.eStop === true,
                    green: state.eStop === false,
                  })}
                  disabled={state.eStop === undefined}
                  value={state.eStop === true ? "enabled" : "disabled"}
                  options={[
                    {
                      label:
                        state.eStop === true
                          ? t("rStopEnabled")
                          : t("rStopEnable"),
                      value: "enabled",
                    },
                    {
                      label:
                        state.eStop === false
                          ? t("rStopDisabled")
                          : t("rStopDisable"),
                      value: "disabled",
                    },
                  ]}
                />
              </PopoverConfirmation>
            </div>
          </div>
        ) : (
          <></>
        )}
      </LoaderWrapper>
    </Can>
  );
};
