import { FC } from "react";
import classNames from "classnames/bind";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import {
  IconBoardArrowRight,
  IconBoardDelivery,
  IconBoardOrder,
  IconBoardPacks,
  IconOrderParts,
} from "../../../assets";
import { Body2, Button1, Caption } from "../../../components/Typography";
import { IOrder } from "../types";
import { getColorByStatus } from "../utils";
import { LMLink } from "../../../components";
import { Tooltip } from "antd";
import { OrderBoardParts } from "./OrderBoardParts";
import styles from "./OrderBoardItem.module.css";

const cx = classNames.bind(styles);

const getShippingStatus = (order: IOrder) => {
  const { shippings } = order;
  if (shippings && shippings[shippings.length - 1]) {
    return shippings[shippings.length - 1].status;
  }
  return undefined;
};

interface Props {
  order: IOrder;
}

export const OrderBoardItem: FC<Props> = ({ order }) => {
  const { t } = useTranslation("orderBoard");
  const { t: tStatus } = useTranslation("order");
  return (
    <LMLink to={`/orders/${order.id}`} className={styles.item}>
      <div className={cx({ "item-line": true, main: true })}>
        <Button1 className={styles.id}>{order.id}</Button1>
        <Body2 className={styles.time}>
          {format(new Date(order.createdOn), TIME_NO_SECONDS_FORMAT)}
        </Body2>
      </div>
      <div className={styles["item-line"]}>
        <div className={styles.title}>
          <IconBoardOrder /> <Caption>{t("order")}</Caption>
        </div>
        <Caption
          className={cx({
            status: true,
            [getColorByStatus(order.status)]: true,
          })}
        >
          {tStatus(order.status)}
        </Caption>
      </div>
      <div className={styles["item-line"]}>
        <div className={styles.title}>
          <IconBoardDelivery /> <Caption>{t("delivery")}</Caption>
        </div>
        <Caption
          className={cx({
            status: true,
            [getColorByStatus(getShippingStatus(order))]: true,
          })}
        >
          {tStatus(getShippingStatus(order) || "noDelivery")}
        </Caption>
      </div>
      <div className={styles["item-line"]}>
        <div className={styles.title}>
          <IconBoardPacks /> <Caption>{t("packs")}</Caption>
        </div>
        <Caption>
          {order.packs?.length || 0} {t("packs")}
        </Caption>
      </div>
      {order.parts && order.parts.length > 0 && (
        <Tooltip
          title={<OrderBoardParts parts={order.parts} />}
          placement="right"
        >
          <div className={styles["order-parts-container"]}>
            <IconOrderParts />
            {order.parts.length}{" "}
            {order.parts.length === 1 ? t("part") : t("parts")}
            <IconBoardArrowRight className={styles["icon-arrow-right"]} />
          </div>
        </Tooltip>
      )}
    </LMLink>
  );
};
