import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { Body2, Button2, H3 } from "../../../components/Typography";
import { FormInstance } from "antd/lib/form/Form";
import { LMDropdown, LMNewInput } from "../../../components";
import { EProductClimax, IProductFormValues, IProductCategory } from "../types";
import { FC, useCallback } from "react";
import { useAppNotifications } from "../../../components/LMNotifications";
import { createCategory, createProduct } from "../api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { fetchCategories, refreshList } from "../slice";
import { IconPlus } from "../../../assets";
import styles from "./CreateProductForm.module.css";
import { LMCheck } from "../../../components/LMCheck";
import { mapProductToCreate } from "./utils";
import { EMetadataTypes, IMetadataEntry } from "../../metadata/types";
import { MetadataFields } from "./MetadataFields";

const NEW_CATEGORY_VALUE = "create-new-category";

interface Props {
  onClose: () => void;
  form: FormInstance<IProductFormValues>;
  categories: IProductCategory[];
  metadataEntries: IMetadataEntry[];
}

export const CreateProductForm: FC<Props> = ({
  onClose,
  form,
  categories,
  metadataEntries,
}) => {
  const { t } = useTranslation("productsPortfolio");

  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();

  const getInitialValues = useCallback(() => {
    const values = { metadata: {} };
    for (const entry of metadataEntries) {
      if (entry.type === EMetadataTypes.Bool) {
        values.metadata = {
          ...values.metadata,
          [entry.name]: false,
        };
      }
    }
    return values;
  }, [metadataEntries]);

  const onFinish = async (values: IProductFormValues) => {
    const category = categories.find(
      (c) => c.id.toString() === values.categoryId
    );
    try {
      if (values.categoryName) {
        const category = await createCategory({ name: values.categoryName });
        await createProduct(
          mapProductToCreate({
            ...values,
            categoryName: category.data.name,
          })
        );
        await dispatch(fetchCategories());
      } else {
        await createProduct(
          mapProductToCreate({
            ...values,
            categoryName: category ? category.name : null,
          })
        );
      }
      await dispatch(refreshList());
      form.resetFields();
      success(t("createProductSuccess"));
      onClose();
    } catch (e: any) {
      error(t("createProductFail"));
    }
  };

  const categoriesItems = categories.map((category) => ({
    name: category.name,
    value: category.id.toString(),
  }));

  const addCategoryItem = {
    name: t("createCategory"),
    value: NEW_CATEGORY_VALUE,
    icon: (
      <div className={styles["create-category-icon"]}>
        <IconPlus stroke="var(--color-primary-80)" />
      </div>
    ),
  };

  const category = Form.useWatch("categoryId", form);

  return (
    <>
      {contextHolder}
      <div className={styles["confirmation-container"]}>
        <div className={styles["confirmation-title"]}>
          <H3>{t("createTitle")}</H3>
        </div>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          initialValues={getInitialValues()}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label={t("productName")}
            rules={[{ required: true, message: t("requiredMessage") }]}
          >
            <LMNewInput placeholder={t("namePlaceholder")} />
          </Form.Item>
          <Form.Item
            name="categoryId"
            label={
              <div className={styles.category}>
                {t("category")}&nbsp;
                <Body2 className={styles.optional}>{t("optional")}</Body2>
              </div>
            }
          >
            <LMDropdown
              placeholder={t("dropdownPlaceholder")}
              items={[addCategoryItem, ...categoriesItems]}
            />
          </Form.Item>
          {category === NEW_CATEGORY_VALUE && (
            <Form.Item name="categoryName" label={t("categoryName")}>
              <LMNewInput placeholder={t("categoryNamePlaceholder")} />
            </Form.Item>
          )}
          <Form.Item
            name="climax"
            label={t("climax")}
            rules={[{ required: true, message: t("requiredMessage") }]}
          >
            <LMDropdown
              placeholder={t("dropdownPlaceholder")}
              items={Object.values(EProductClimax).map((value) => ({
                name: value,
                value,
              }))}
            />
          </Form.Item>
          <Form.Item name="external" valuePropName="checked">
            <div className={styles["check-container"]}>
              <LMCheck />{" "}
              <Button2 className={styles["check-label"]}>
                {t("external")}
              </Button2>
            </div>
          </Form.Item>
          <MetadataFields entries={metadataEntries} />
        </Form>
      </div>
    </>
  );
};
