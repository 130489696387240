import { AxiosPromise } from 'axios';
import { axios } from '../axios';
import { IStoreProduct } from './types';

export const getProducts = (
  tenantId: number,
): AxiosPromise<IStoreProduct[]> => {
  return axios.get(`/tenants/${tenantId}/available-stock`);
}

export const placeOrder = (tenantId: number, products: IStoreProduct[]) => {
  const orderProducts = products.map(product => ({
    sku: product.sku,
    quantity: product.quantity,
    name: product.name,
  }));
  const invoice = {
    ClientAddress: "הזרם 7",
    CustomerPhoneNumber: "0500000000",
    ClientCity: "תל אביב",
    ClientName: "בדיקה",
    ClientNumber: "7980768"
  };
  return axios.post(`/orders/create?tenantId=${tenantId}`, { products: orderProducts, invoice });
}
