import { FC, useCallback, useEffect, useState } from "react";
import { Form, message, notification } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ICreateIndexingRequestValues, IIndexingRangeForm } from "../types";
import { actions, fetchMaintenancesForIndexing, refreshList } from "../slice";
import { createIndexingRequest } from "../api";
import { AppDispatch } from "../../../app/store";
import { LMButton, LMSelect, Option } from "../../../components";
import { IMaintenance } from "../../maintenance/types";
import { CreateRangeForm } from "../CreateRangeForm";
import { mapRanges, mergeOverlappingRanges } from "../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IStation } from "../../system";
import styles from "./CreateIndexingRequestForm.module.css";
import { EPalletType } from "../../system/types";

interface Props {
  systemId: number;
  isDrawerOpen: boolean;
  maintenances: IMaintenance[];
  stations: IStation[];
}

export const CreateIndexingRequestForm: FC<Props> = ({
  systemId,
  isDrawerOpen,
  maintenances,
  stations,
}) => {
  const [ranges, setRanges] = useState<IIndexingRangeForm[]>([]);
  const [rangeFormVisible, setRangeFormVisible] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("indexing");

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleFetchMaintenances = useCallback(() => {
    if (!isDrawerOpen) return;
    dispatch(fetchMaintenancesForIndexing({ systemId }));
  }, [systemId, dispatch, isDrawerOpen]);

  useEffect(() => {
    handleFetchMaintenances();
  }, [handleFetchMaintenances]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.get("create")) {
      dispatch(actions.setIsCreateDrawerOpen(true));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (isDrawerOpen) return;
    setRanges([]);
    form.resetFields();
  }, [isDrawerOpen, form]);

  const create = async (values: ICreateIndexingRequestValues) => {
    return createIndexingRequest(systemId, values);
  };

  const handleCreateIndexingRange = (values: IIndexingRangeForm) => {
    if (!values.side) {
      const updatedRanges = ranges.filter(
        (r) => r.palletId !== values.palletId
      );
      setRanges([...updatedRanges, values]);
      return;
    }
    const updatedRanges = mergeOverlappingRanges([...ranges, values]);
    setRanges(updatedRanges);
    setRangeFormVisible(false);
  };

  const handleRemoveIndexingRange = (values: IIndexingRangeForm) => {
    const updatedRanges = ranges.filter((r) => r.id !== values.id);
    setRanges(updatedRanges);
  };

  const handleFilterStations = (): IStation[] => {
    const filteredPallets = stations.filter((station) => {
      const isFullPalletInRange = ranges.find(
        (range) => range.palletId === station.palletId && !range.side
      );
      return (
        !isFullPalletInRange &&
        (station.type === EPalletType.Hive ||
          station.type === EPalletType.SingleItemHive)
      );
    });
    return filteredPallets;
  };

  const onFinish = async (values: ICreateIndexingRequestValues) => {
    try {
      setSubmitting(true);
      await create({
        maintenanceId: values.maintenanceId,
        ranges: mapRanges(ranges),
      });
      dispatch(refreshList({ systemId }));
      notification.success({
        message: t("createSuccessTitle"),
        description: t("createSuccessMessage"),
      });
      dispatch(actions.setPage(1));
      dispatch(actions.setIsCreateDrawerOpen(false));
    } catch (e: any) {
      message.error(t("createFail"));
    } finally {
      setSubmitting(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log({ errorInfo });
  };

  const onCloseHandler = () => {
    dispatch(actions.setIsCreateDrawerOpen(false));
  };

  return (
    <div className={styles.form}>
      <Form
        layout={"vertical"}
        form={form}
        autoComplete="off"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <main className={styles["form-content"]}>
          <Form.Item label={t("maintenanceId")} name="maintenanceId">
            <LMSelect
              size={"large"}
              allowClear
              optionLabelProp="label"
              showSearch
            >
              {maintenances.map((maintenance) => (
                <Option
                  label={maintenance.id}
                  key={maintenance.id}
                  value={maintenance.id}
                >
                  <div className={styles.flex}>{maintenance.id}</div>
                </Option>
              ))}
            </LMSelect>
          </Form.Item>
          <h3 className={styles["ranges-title"]}>{t("ranges")}</h3>
          {ranges.map((range) => (
            <CreateRangeForm
              key={`${range.palletId}-${range.side}-${range.start}-${range.end}`}
              systemId={systemId}
              stations={stations}
              values={range}
              onRemove={handleRemoveIndexingRange}
            />
          ))}
          {!rangeFormVisible && (
            <LMButton
              className={styles["create-range-button"]}
              size={"large"}
              type="primary"
              block
              onClick={() => setRangeFormVisible(true)}
            >
              {t("createRange")}
            </LMButton>
          )}
          {isDrawerOpen && rangeFormVisible && (
            <CreateRangeForm
              systemId={systemId}
              stations={handleFilterStations()}
              onAddRange={handleCreateIndexingRange}
            />
          )}
          <div className={styles["range-tip"]}>
            <InfoCircleOutlined />
            {ranges.length === 0 ? (
              t("rangeTipNoRanges")
            ) : (
              <>
                {ranges.length}{" "}
                {ranges.length === 1 ? t("rangeOne") : t("rangeMore")}{" "}
                {t("rangeTip")}
              </>
            )}
          </div>
        </main>
        <footer>
          <Form.Item className={styles["button-cancel"]}>
            <LMButton
              type="default"
              size={"large"}
              block
              disabled={submitting}
              onClick={onCloseHandler}
            >
              {t("cancel")}
            </LMButton>
          </Form.Item>

          <Form.Item className={styles["button-create"]}>
            <LMButton
              size={"large"}
              type="primary"
              block
              disabled={submitting || ranges.length === 0}
              htmlType="submit"
              loading={submitting}
            >
              {t("create")}
            </LMButton>
          </Form.Item>
        </footer>
      </Form>
    </div>
  );
};
