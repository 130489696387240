import { Form, RadioChangeEvent } from "antd";
import { Col, Row } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  LMRange,
  LMRadio,
  LMSelect,
  Option,
  RadioButton,
  LMButton,
} from "../../../components";
import { SliderMarks } from "antd/lib/slider";
import { IIndexingRangeForm } from "../types";
import { generateId } from "../utils";
import { IStation } from "../../system";
import { fetchStations } from "../../system";
import styles from "./CreateRangeForm.module.css";

interface Props {
  systemId: number;
  stations: IStation[];
  values?: IIndexingRangeForm;
  onAddRange?: (rangeValues: IIndexingRangeForm) => void;
  onRemove?: (rangeValues: IIndexingRangeForm) => void;
}

const MAX_PALLET_RANGE = 23;

export const CreateRangeForm: FC<Props> = ({
  systemId,
  stations,
  values,
  onAddRange,
  onRemove,
}) => {
  const DEFAULT_RANGE: [number, number] = [0, MAX_PALLET_RANGE];
  const [palletId, setPalletId] = useState<string | undefined>(
    values ? values.palletId : undefined
  );
  const [side, setSide] = useState<"A" | "B" | undefined>(
    values ? values.side : undefined
  );
  const [range, setRange] = useState<[number, number]>(
    (values?.start || values?.start === 0) && (values?.end || values?.end === 0)
      ? [values.start, values.end]
      : DEFAULT_RANGE
  );
  const { t } = useTranslation("indexing");
  const dispatch = useDispatch<AppDispatch>();

  const handleFetchStations = useCallback(() => {
    dispatch(fetchStations(systemId));
  }, [systemId, dispatch]);

  useEffect(() => {
    handleFetchStations();
  }, [handleFetchStations]);

  const marks: SliderMarks = {
    0: "0",
    23: MAX_PALLET_RANGE,
  };

  const handleChangeSide = (e: RadioChangeEvent) => {
    setSide(e.target.value);
  };

  const handleChangePallet = (value: string) => {
    if (!value) {
      setRange(DEFAULT_RANGE);
      setSide(undefined);
    }
    setPalletId(value);
  };

  const handleChangeRange = (value: [number, number]) => {
    setRange(value);
  };

  const handleResetValues = () => {
    setPalletId(undefined);
    setSide(undefined);
    setRange(DEFAULT_RANGE);
  };

  const handleAdd = () => {
    if (!onAddRange || !palletId) return;
    onAddRange({
      id: generateId(),
      palletId,
      side,
      start: side ? range[0] : undefined,
      end: side ? range[1] : undefined,
    });
    handleResetValues();
  };

  const handleRemove = () => {
    if (!values || !onRemove) return;
    onRemove(values);
  };

  return (
    <div className={!values ? styles.active : undefined}>
      {!values && (
        <h3 className={styles["range-form-title"]}>{t("rangeTitle")}</h3>
      )}
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t("pallet")}>
            <LMSelect
              size={"large"}
              onChange={handleChangePallet}
              allowClear
              optionLabelProp="label"
              showSearch
              disabled={Boolean(values)}
              value={palletId}
            >
              {stations
                .filter((s) => s.palletId)
                .map((station) => (
                  <Option
                    label={station.palletId}
                    key={station.palletId}
                    value={station.palletId}
                  >
                    <div className={styles.flex}>{station.palletId}</div>
                  </Option>
                ))}
            </LMSelect>
          </Form.Item>
        </Col>
        <Col span={6}>
          {palletId && (
            <Form.Item label={t("side")}>
              <LMRadio
                onChange={handleChangeSide}
                disabled={Boolean(values)}
                value={side}
              >
                <RadioButton value="A">A</RadioButton>
                <RadioButton value="B">B</RadioButton>
              </LMRadio>
            </Form.Item>
          )}
        </Col>
        <Col span={7}>
          {side && palletId && (
            <Form.Item label={t("range")}>
              <LMRange
                disabled={Boolean(values)}
                marks={marks}
                range
                min={0}
                max={MAX_PALLET_RANGE}
                defaultValue={range}
                value={range}
                onChange={handleChangeRange}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={5} className={styles["button-container"]}>
          {Boolean(values) ? (
            <LMButton type="primary" size="large" danger onClick={handleRemove}>
              {t("remove")}
            </LMButton>
          ) : (
            <Form.Item>
              <LMButton
                type="primary"
                disabled={!palletId}
                formAction="button"
                className={styles["add-button"]}
                size="large"
                onClick={handleAdd}
              >
                {t("confirm")}
              </LMButton>
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  );
};
