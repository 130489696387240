import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { LMNewInput } from "../../../components";
import { addProductSku } from "../api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { refreshList, refreshSelectedProduct } from "../slice";
import { BarCodesSelector } from "../BarCodesSelector";
import { LMNewButton } from "../../../components/LMNewButton";
import styles from "./ProductSkuForm.module.css";

interface Props {
  onClose: () => void;
  productId: number;
  onSuccess: () => void;
  onError: () => void;
}

export const ProductSkuForm: FC<Props> = ({
  onClose,
  productId,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation("productsPortfolio");
  const [form] = Form.useForm<{ sku: string; barcodes: string[] }>();
  const dispatch = useDispatch<AppDispatch>();

  const onFinish = async (values: { sku: string; barcodes: string[] }) => {
    try {
      await addProductSku(productId, values);
      onSuccess();
      await dispatch(refreshList());
      await dispatch(refreshSelectedProduct());
      form.resetFields();
      onClose();
    } catch {
      onError();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="sku" label={t("sku")}>
            <LMNewInput placeholder={t("skuNamePlaceholder")} />
          </Form.Item>
          <Form.Item name="barcodes" label={t("barCodes")}>
            <BarCodesSelector placeholder={t("barCodesPlaceholder")} />
          </Form.Item>
          <div className={styles.buttons}>
            <LMNewButton color="default" onClick={onClose}>
              {t("cancel")}
            </LMNewButton>
            <LMNewButton type="submit">{t("addSku")}</LMNewButton>
          </div>
        </Form>
      </div>
    </>
  );
};
