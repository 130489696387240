import { useTranslation } from "react-i18next";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "antd";
import classNames from "classnames/bind";
import { MenuProps } from "rc-menu";
import { useCallback, useEffect, useState } from "react";
import { ApiStatuses } from "../../../app/types";
import { LoaderWrapper } from "../../../components";
import { IconDropdownArrow, IconLogout, IconUser } from "../../../assets";
import { clearEnvironment, clearUser } from "../utils";
import styles from "./UserInfo.module.css";

const cx = classNames.bind(styles);

export const UserInfo = () => {
  const { getIdTokenClaims, isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation("auth");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IdToken>();
  const [open, setOpen] = useState(false);

  const fetchClaims = useCallback(async () => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }
    const timeout = setTimeout(() => {
      getIdTokenClaims().then((idTokenClaims) => {
        if (!idTokenClaims) {
          setLoading(false);
          return;
        }
        setUser(idTokenClaims);
        setLoading(false);
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    fetchClaims();
  }, [fetchClaims]);

  const handleLogout = () => {
    clearEnvironment();
    clearUser();
    logout();
  };

  const items: MenuProps["items"] = [
    {
      label: t("logout"),
      key: t("logout"),
      onClick: () => handleLogout(),
      itemIcon: <IconLogout />,
    },
  ];

  return (
    <LoaderWrapper status={loading ? ApiStatuses.loading : ApiStatuses.success}>
      <>
        {user && (
          <Dropdown
            menu={{ items, className: styles.menu }}
            className={styles.dropdown}
            onOpenChange={setOpen}
          >
            <div className={cx({ label: true, active: open })}>
              <IconUser />
              <span className={cx({ text: true })}>{user.name}</span>
              <span className={cx({ "dropdown-icon": true, rotated: open })}>
                <IconDropdownArrow
                  stroke={
                    open ? "var(--color-primary-80)" : "var(--color-gray-70)"
                  }
                />
              </span>
            </div>
          </Dropdown>
        )}
      </>
    </LoaderWrapper>
  );
};
