import { FC } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EKeyPrivilegeOperations, ICreateKeyDto, KeyType } from "../types";
import { actions, create, fetchList } from "../slice";
import { AppDispatch, RootState } from "../../../app/store";
import { LMNewInput, LMDropdown } from "../../../components";
import { FormInstance } from "antd/lib/form/Form";
import { useAppNotifications } from "../../../components/LMNotifications";
import { Body2, H3 } from "../../../components/Typography";
import { PhoneInput } from "../../../components/PhoneInput";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import styles from "./CreateKeyModal.module.css";

interface Props {
  systemId: number;
  form: FormInstance<ICreateKeyDto>;
  onClose: () => void;
}

export const CreateKeyForm: FC<Props> = ({ systemId, form, onClose }) => {
  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("keys");

  const filters = useSelector((state: RootState) => {
    return state.keys.filters;
  });

  const onFinish = async (values: ICreateKeyDto) => {
    try {
      await dispatch(create(values)).unwrap();
      await dispatch(fetchList({ systemId, filters })).unwrap();
      success(t("createSuccessMessage"));
      dispatch(actions.setPage(1));
      onClose();
    } catch (e: any) {
      error(t("createErrorMessage"));
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <div className={styles.title}>
          <H3>{t("createFormTitle")}</H3>
        </div>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label={t("createFormName")}
            rules={[{ required: true, message: t("required") }]}
          >
            <LMNewInput placeholder={t("createFormNamePlaceholder")} />
          </Form.Item>
          <Form.Item
            name="privileges"
            label={t("createFormPrivileges")}
            rules={[{ required: true, message: t("required") }]}
          >
            <LMMultiSelect
              placeholder={t("pleaseSelect")}
              items={Object.keys(EKeyPrivilegeOperations).map((operation) => ({
                name: t(operation),
                value: operation,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={t("type")}
            name="type"
            rules={[{ required: true, message: t("required") }]}
          >
            <LMDropdown
              items={Object.keys(KeyType).map((key) => ({
                name: (
                  <div>
                    <div>{t(key)}</div>
                    <Body2 className={styles["key-type-description"]}>
                      {t(`${key}Description`)}
                    </Body2>
                  </div>
                ),
                value: key,
              }))}
              placeholder={t("pleaseSelect")}
            />
          </Form.Item>
          <Form.Item label={t("createFormPhone")} name="phoneNumber">
            <PhoneInput />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
