import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import { RelevanceDot } from "../../../components";
import {
  IIndexCellProduct,
  IIndexCellProductPaired,
  IIndexPalletRequest,
  IndexingTypes,
} from "../types";
import styles from "./IndexingDetails.module.css";

export const mapProductIndexPairs = (
  cellProducts: IIndexCellProduct[]
): IIndexCellProductPaired[] => {
  const pairs = [] as IIndexCellProductPaired[];
  const lastCells = cellProducts.filter((p) => p.cellUpdate === "Last");
  const newCells = cellProducts.filter((p) => p.cellUpdate === "New");
  newCells.forEach((newCellPair) => {
    const lastCellPair = lastCells.find(
      (lastCell) =>
        lastCell.id === newCellPair.id &&
        lastCell.palletId === newCellPair.palletId
    );
    let pair: IIndexCellProductPaired = {
      id: newCellPair.id,
      palletId: newCellPair.palletId,
      productIndexType: newCellPair.productIndexType,
      quantityNew: newCellPair.quantity,
      skuNew: newCellPair.sku,
    };
    if (lastCellPair) {
      pair = {
        ...pair,
        quantityLast: lastCellPair.quantity,
        skuLast: lastCellPair.sku,
      };
    }
    pairs.push(pair);
  });
  return pairs;
};

export const getCompareTableColumns = (
  t: TFunction
): ColumnType<IIndexCellProductPaired>[] => {
  return [
    {
      title: t("palletId") as string,
      dataIndex: "palletId",
      key: "palletId",
    },
    {
      title: t("cellID") as string,
      dataIndex: "id",
      key: "id",
    },
    {
      title: (
        <div className={styles["status-cell-container"]}>
          <>
            {t("sku")} <RelevanceDot />
          </>
        </div>
      ),
      dataIndex: "skuLast",
      key: "skuLast",
    },
    {
      title: (
        <div className={styles["status-cell-container"]}>
          <>
            {t("sku")} <RelevanceDot isRelevant />
          </>
        </div>
      ),
      dataIndex: "skuNew",
      key: "skuNew",
    },
    {
      title: (
        <div className={styles["status-cell-container"]}>
          <>
            {t("quantity")} <RelevanceDot />
          </>
        </div>
      ),
      dataIndex: "quantityLast",
      key: "quantityLast",
    },
    {
      title: (
        <div className={styles["status-cell-container"]}>
          <>
            {t("quantity")} <RelevanceDot isRelevant />
          </>
        </div>
      ),
      dataIndex: "quantityNew",
      key: "quantityNew",
    },
    {
      title: t("indexType") as string,
      dataIndex: "productIndexType",
      key: "productIndexType",
    },
  ];
};

export const getRequestsTableColumns = (
  t: TFunction
): ColumnType<IIndexPalletRequest>[] => {
  return [
    {
      title: t("palletId") as string,
      dataIndex: "palletId",
      key: "palletId",
    },
    {
      title: t("side") as string,
      dataIndex: "side",
      key: "side",
    },
    {
      title: t("start") as string,
      dataIndex: "start",
      key: "start",
    },
    {
      title: t("end") as string,
      dataIndex: "end",
      key: "end",
    },
  ];
};

export const calculateTotalForType = (
  cells: IIndexCellProductPaired[],
  type: IndexingTypes
): number => {
  let number = 0;
  cells.forEach((cell) => {
    if (cell.productIndexType === type) number += cell.quantityNew;
  });
  return number;
};
